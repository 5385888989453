<template>
  <div>   
    <div class="container container-global">
      <div class="wrap-form">
          <div class="form-signin main-overlay">
              <div class="mb-4 text-center"><img  src="@/assets/images/logo.svg" style="height: 40px; "></div>
              
              <AlertBox v-if="message != '' " :message="message" variant="danger"/> 
              <div class="form-group form-group-cs mb-3" v-if="step == 1">
                <input type="text" class="form-control form-cs" v-model="username" :placeholder="'E-Mail'" required />
                <button class="mt-2 btn-lg w-100 btn-orange" @click="validate()">Get started</button>
              </div>  

              <form method="post" @submit.prevent="submitForm" v-else-if="step == 2">
                  <div class="form-group form-group-cs mb-3">
                    <input type="text" class="form-control form-cs" v-model="username" readonly required />
                  </div>
                  <div class="form-group form-group-cs">
                    <input type="password" class="form-control form-cs" v-model="password" :placeholder="(browser_lang == 'fr') ? 'Mot de passe' : 'Password'" required />
                  </div>
                  <div class="mt-2">
                    <div class="text-link pull-left">
                      <input class="form-check-input" type="checkbox" id="rememberMe" v-model="remember"><label for="rememberMe"> {{(browser_lang == 'fr') ? 'Se souvenir de moi' : 'Remember me'}}</label> | 
                      <router-link to="/forgot-password">{{(browser_lang == 'fr') ? 'Mot de passe oublié ?' : 'Forgot your password?'}}</router-link>
                    </div>
                    <div class="pull-right">
                      <router-link to="/register" class="btn btn-border-orange">{{(browser_lang == 'fr') ? 'INSCRIPTION' : 'REGISTER'}}</router-link>
                      <button class="btn btn-orange" type="submit">{{(browser_lang == 'fr') ? 'CONNEXION' : 'SIGN IN'}} <i class="fas fa-chevron-right" style="margin-left:8px"></i></button>
                    </div>
                  </div>
                  <div v-if="loading" class="overlay">
                    <div class="loadding_circle"></div>
                  </div>
              </form>

          </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import AlertBox from "@/components/Layout/AlertBox.vue";
import { isLogin, homePage, isUserInter, isUserFrench, getBrowserLang } from "@/utils";

export default {
  name: "Login",
  components: {
    AlertBox,
  },
  data() {
    return {
      username: "",
      password: "",
      remember: false,
      loading: false,
      message: "",
      browser_lang: null,
      step: 1
    };
  }, 
  beforeCreate() {
    if (isLogin()) {       
      this.$router.push(homePage()); 
    }
  }, 
  methods: {
    validate(){
      const username = this.username;

      this.$store.dispatch("auth/check_user_oidc", {username} ).then(
        () => {
          console.log(this.chcekInternalUser)
          if(this.chcekInternalUser){
            this.$store.dispatch("auth/get_authorize_endpoint").then(
              () => {
                window.location.href = localStorage.getItem('oidc_endpoint');
              }
            );
          } else {
            this.step = 2
          }
        }
      );
    },
    submitForm() {
      this.message = ""
      const { username, password, remember } = this;
      if (this.username && this.password) {
        this.loading = true;
        this.$store.dispatch("auth/login", { username, password, remember }).then(
          () => {
            if(localStorage.redirectTo){
                if((localStorage.redirectTo.includes('inter') && !isUserInter()) ||
                  (localStorage.redirectTo.includes('simple') && !isUserFrench()) ||
                  (localStorage.redirectTo.includes('advanced_result') && !isUserFrench())
                ){
                  this.loading = false;
                  this.message = "You have no access to this page";
                  this.$store.dispatch("auth/logout");
                }else{
                  this.$router.push(localStorage.redirectTo)
                  localStorage.removeItem('redirectTo')
                }
            } else
                this.$router.push(homePage());
          },
          (error) => {            
            this.loading = false;
            let tempmsg = (error && error.response);
            if (tempmsg && tempmsg.status === 401) {
              this.message = "Username or password is incorrect.";
            }else if(tempmsg && tempmsg.data && tempmsg.data.message){
              this.message = tempmsg.data.message;
            }else {
              this.message = "Username or password is incorrect.";
            }
          }
        );
      }
    }, 
  },
  mounted() {
    this.browser_lang = getBrowserLang()
  },
  computed : {
    chcekInternalUser() {  
        return this.$store.getters['auth/is_internal_user']            
    },
  }
};
</script>

<style scoped>
.form-signin {
    width: 100%;
    max-width: 480px;
    margin: auto;
    color: #212529; 
    padding: 30px;   
    background-color: #fff;
    box-shadow: 0 0 11px 0 rgb(0 0 0 / 10%);    
}
.form-signin .text-link { text-align: left;}
.form-signin .text-link label { padding-left: 5px;}
.form-signin .text-link a {
    color:inherit; 
    text-decoration: none;
}
.form-signin .btn {
    margin-left: 5px;
    padding: 6px 15px !important;
    font-weight: 600;
}
/* 
.form-signin {
    width: 100%;
    max-width: 450px;
    margin: auto;
    text-align: center;
    font-size: 13px;
} */
/* 
.form-signin small { font-size: 13px;}
.form-signin .text-link { text-align: left;}
.form-signin .text-link label { padding-left: 5px;}
.form-signin .text-link a {
    color: inherit; 
    text-decoration: none;
}
.form-signin .btn{margin-left: 5px;}
.btn-border-blue{font-size: 12px; box-shadow: inset 0px 0px 0px 2px #1099d6; color: #1099d6;}
  */

/* .btn-orange {
  border: none;
}
.title-form a {
  color: #7d8693;
  font-size: 11px;
}
.form-signin {
  max-width: 375px;
} */
</style>